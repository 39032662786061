/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';
import {
  Sequence,
  GetSequencesQueryParams,
  SequenceStep,
  ContactsPagination,
  ContactRequestResponse,
  StepFilterResponse,
  SequenceStats,
  VariantStats,
  SequenceConfig,
  ContactStatusFilter,
  ImportCSVReport,
  AttachmentUploadResponse,
  SelectedProspectData,
  SequenceScoreType,
  GetLinkedEmailAccountsResponse,
  GetAllNotLinkedEmailAccountsResponse,
  GetSenderEmailsResponse,
  SequenceStatsCounts,
} from './types';
import { SequenceSortBy } from './enums';
import {
  createSequenceRequest,
  getSequenceRequest,
  getSequencesRequest,
  pauseResumeSequenceRequest,
  renameSequenceRequest,
  cloneSequenceRequest,
  deleteSequenceRequest,
  deleteSequencesRequest,
  addSequenceStepRequest,
  updateSequenceStepExecutionDayRequest,
  updateSequenceStepRequest,
  deleteSequenceStepRequest,
  cloneSequenceStepVariantRequest,
  addSequenceStepVariantRequest,
  updateSequenceStepVariantDataRequest,
  updateSequenceStepVariantStatusRequest,
  deleteSequenceStepVariantRequest,
  updateSequenceSettingsRequest,
  getContactFieldsRequest,
  getSequenceStepsRequest,
  addContactsFromCSVRequest,
  getSchedulesRequest,
  getConnectedEmailsRequest,
  getContactStatusCountRequest,
  getSequenceContactListRequest,
  changeStatusOfContacts,
  getContactSequenceListRequest,
  getContactSequenceStepListRequest,
  addContactsToStepRequest,
  removeContactFromSequenceRequest,
  addToStepModalRequest,
  getProspectCountRequestModal,
  getProspectRequestModal,
  finishOnboardingRequest,
  getAdminSettingsRequest,
  getSequencesStatsRequest,
  sequenceSendEmailStatus,
  getSequenceStatsRequest,
  getSequenceConfigRequest,
  getSequencePriorityConfigRequest,
  updateSequencePriorityConfigRequest,
  createSampleStepsRequest,
  addSampleProspectsToStepRequest,
  getMyTemplateRequest,
  getTeamTemplateRequest,
  getEmailStatusOfStepRequest,
  getEmailStepOfStatusRequest,
  getEmailListRequest,
  getCustomDomainsRequest,
  getSequenceProspectVerificationStatsRequest,
  sendSequenceProspectVerifyStartRequest,
  uploadAttachmentRequest,
  deleteAttachmentRequest,
  getSentEmailPreviewRequest,
  getSequenceScoreRequest,
  createEmailWritingFactorsRequest,
  getLinkedEmailAccountsRequest,
  linkEmailToSequenceRequest,
  getAllNotLinkedEmailAccountsRequest,
  removeConnectedEMailAccountRequest,
  getNotLinkedEmailAccountsCountRequest,
  getSenderEmailsRequest,
  onOutcomeChangeRequest,
  getTaskAssigneeListRequest,
  bulkPauseSequenceRequest,
  bulkResumeSequenceRequest,
  changeEmailStatusRequest,
  getAllConnectedEmailAccountsRequest,
  getSequenceTimezoneRequest,
  sequenceProspectBulkStatusUpdateRequest,
  sequenceProspectBulkTagAssignRequest,
  sequenceProspectBulkTagUnAssignRequest,
  sequenceProspectBulkAddToSequenceRequest,
  sequenceProspectBulkRemoveFromSequenceRequest,
  getSequenceStatsCountsRequest,
} from './extra-actions';
import { Order } from '../../shared/enums/order';
import { ResponseErrorWithHandled } from '../../shared/types';
import { Field } from '../settings/types/field';
import { Schedule } from '../settings/types/schedule';
import { EmailAccount } from '../settings/types/email-account';
import { Template, TemplateMeta } from './shared/modals/template-modal/types';
import { EmailStatus, Mail } from './types/emails';
import { constants } from '../../shared/enums/constants';
import {
  ProspectFilterResetArg,
  ProspectsFilters,
  SequenceEmailsFilterResetArg,
} from '../../shared/types/prospects-filters';
import { CustomDomainWithEmailAccountType } from '../settings/types/custom-domain';
import {
  SequenceProspectsVerificationStatsResponse,
  SequenceProspectsVerifyStartResponse,
} from './types/sequence-prospect-verification-stats';
import { SentEmailPreview } from '../../shared/components/email-preview-modal/type';
import {
  getSingleProspectDetailsRequest,
  getSingleProspectSequencesDetailsRequest,
} from '../prospect/extra-actions';
import { EmailFilter } from './components/sequence-single-content/components/sequence-single-emails/components/email-filter-modal/types';
import { SelectAllContacts } from '../prospect/types';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

export type EmailListResponse = {
  emails: {
    bounced: {
      status: boolean;
      reason?: string;
      manual: boolean;
    };
    count: {
      clicked: number;
      replied: number;
      opened: number;
      sent: number;
    };
    contact: {
      name: string;
      email: string;
      id: number;
    };
    id: number;
    subject: string;
    stepNumber: number;
    time: {
      value: Date;
      type: Mail;
    };
    activity: {
      sentAt: Date;
      openedAt: Date;
      clickedAt: Date;
      repliedAt: Date;
      bouncedAt: Date;
    };
    emailAccountId: {
      emailAccountId: number;
      fromEmail: string;
      fromName: string;
    };
    variantId: number;
    variantNumber: number;
  }[];
  timeZone: string;
  currentPage: number;
  totalCount: number;
  hasEmails: boolean;
  isQueued: boolean;
  isSkipped?: boolean;
};

export const prospectsFiltersInitialState: ProspectsFilters = {
  conversationType: [],
  tags: [],
  owners: [],
  status: [],
  outcomes: [],
  sequences: [],
  'verification status': [],
  'task priority': [],
  'created date': [],
  'creation source': [],
  'current step': [],
  'email account': [],
  'client associated': [],
};

export const sequenceEmailsFiltersInitialState: EmailFilter = {
  emailAccountIds: [],
  variantNumbers: [],
  sentAt: [],
};

type State = {
  createSequenceRequest: RequestState;
  getSequenceRequest: RequestState;
  getSequencesRequest: RequestState;
  getSequenceStatsRequest: RequestState;
  getSequencesStatsRequest: RequestState;
  renameSequenceRequest: RequestState;
  pauseResumeSequenceRequest: RequestState;
  cloneSequenceRequest: RequestState;
  deleteSequenceRequest: RequestState;
  deleteSequencesRequest: RequestState;
  addSequenceStepRequest: RequestState;
  createSampleStepsRequest: RequestState;
  updateSequenceStepExecutionDayRequest: RequestState;
  updateSequenceStepRequest: RequestState;
  deleteSequenceStepRequest: RequestState;
  cloneSequenceStepVariantRequest: RequestState;
  addSequenceStepVariantRequest: RequestState;
  updateSequenceStepVariantDataRequest: RequestState;
  updateSequenceStepVariantStatusRequest: RequestState;
  deleteSequenceStepVariantRequest: RequestState;
  updateSequenceSettingsRequest: RequestState;
  getContactFieldsRequest: RequestState;
  getSequenceStepsRequest: RequestState;
  addContactsFromCSVRequest: RequestState;
  getSchedulesRequest: RequestState;
  getConnectedEmailsRequest: RequestState;
  getContactStatusCountRequest: RequestState;
  getSequenceContactListRequest: RequestState;
  changeStatusOfContacts: RequestState;
  getContactSequenceListRequest: RequestState;
  removeContactFromSequenceRequest: RequestState;
  getContactSequenceStepListRequest: RequestState;
  addContactsToStepRequest: RequestState;
  addSampleProspectsToStepRequest: RequestState;
  getProspectRequest: RequestState;
  getProspectCountRequest: RequestState;
  addToStepRequest: RequestState;
  finishOnboardingRequest: RequestState;
  getAdminSettings: RequestState;
  getSequenceEmailSendRequest: RequestState;
  getSequenceConfigRequest: RequestState;
  getSequencePriorityConfigRequest: RequestState;
  updateSequencePriorityConfigRequest: RequestState;
  getMyTemplateRequest: RequestState;
  getTeamTemplateRequest: RequestState;
  getEmailStatusOfStepRequest: RequestState;
  getEmailStepOfStatusRequest: RequestState;
  getEmailListRequest: RequestState;
  getCustomDomainsRequest: RequestState;
  getSequenceProspectVerificationStatsRequest: RequestState;
  sendSequenceProspectVerifyStartRequest: RequestState;
  uploadAttachmentRequest: RequestState;
  deleteAttachmentRequest: RequestState;
  getSentEmailPreviewRequest: RequestState;
  getSingleProspectDetailsRequest: RequestState;
  getSingleProspectSequencesDetailsRequest: RequestState;
  getSequenceScoreRequest: RequestState;
  createEmailWritingFactorsRequest: RequestState;
  getLinkedEmailAccountsRequest: RequestState;
  linkEmailToSequenceRequest: RequestState;
  getAllNotLinkedEmailAccountsRequest: RequestState;
  removeConnectedEMailAccountRequest: RequestState;
  getNotLinkedEmailAccountsCountRequest: RequestState;
  getSenderEmailsRequest: RequestState;
  onOutcomeChangeRequest: RequestState;
  getTaskAssigneeListRequest: RequestState;
  bulkPauseSequenceRequest: RequestState;
  bulkResumeSequenceRequest: RequestState;
  changeEmailStatusRequest: RequestState;
  getAllConnectedEmailAccountsRequest: RequestState;
  getSequenceTimezoneRequest: {
    [id: number]: RequestState;
  };
  getSequenceTimezoneResponse: {
    [id: number]: string;
  };
  sequenceProspectBulkStatusUpdateRequest: RequestState;
  sequenceProspectBulkTagAssignRequest: RequestState;
  sequenceProspectBulkTagUnAssignRequest: RequestState;
  sequenceProspectBulkAddToSequenceRequest: RequestState;
  sequenceProspectBulkRemoveFromSequenceRequest: RequestState;
  getSequenceStatsCountsRequest: RequestState;
  uploadedAttachment: AttachmentUploadResponse;
  deletedAttachmentId: number;
  pagination: {
    options: Required<GetSequencesQueryParams>;
    pagesCount: number;
    count: number;
  };
  contactPagination: ContactsPagination;
  schedules: Schedule[];
  connectedEmails: EmailAccount[];
  totalSequences: number;
  totalSelectedSequences: number;
  sequenceStats: VariantStats[];
  sequencesStats: SequenceStats[];
  sequences: Sequence[];
  sequence: Sequence;
  createdSequenceId: number;
  selectedSequence: any;
  contactFields: Field[];
  steps: SequenceStep[];
  importCSVReport: ImportCSVReport;
  contacts: ContactRequestResponse[];
  contactStepFilterCount: StepFilterResponse;
  contactStatusFilterCount: ContactStatusFilter;
  selectedContacts: any[];
  contactSequence: any[];
  contactStep: any[];
  addToSequenceResultCount: {
    failed: number;
    successful: number;
    resultData: any;
  };
  addToSequenceResultFromModalCount: {
    failed: number;
    successful: number;
    resultData: any;
  };
  pauseResumeResultCount: {
    failed: number;
    success: number;
  };
  prospects: any[];
  prospectCount: number;
  adminSettings: any[];
  isSequenceMailSending: boolean;
  sequenceConfig: SequenceConfig;
  myTemplates: Template[];
  myTemplatesMeta: TemplateMeta;
  teamTemplates: Template[];
  teamTemplatesMeta: TemplateMeta;
  emailStatusOfStep: {
    total: number;
    status: {
      type: EmailStatus;
      count: number;
    }[];
    isSkipped?: boolean;
  };
  emailStepOfStatus: {
    total: number;
    steps: {
      id: number;
      number: number;
      count: number;
    }[];
  };
  emailList: EmailListResponse;
  sequenceProspectsFilters: ProspectsFilters;
  customDomains: CustomDomainWithEmailAccountType[];
  sequenceProspectsVerificationStatsResponse: SequenceProspectsVerificationStatsResponse;
  sequenceProspectsVerifyStartResponse: SequenceProspectsVerifyStartResponse;
  sentEmailPreview: SentEmailPreview;

  selectedProspectId: number;
  selectedProspectData: SelectedProspectData;
  selectedProspectSequences: any[];
  createEmailWritingScoreForStepOne: boolean;
  sequenceScore: SequenceScoreType;
  getLinkedEmailAccountsResponse: GetLinkedEmailAccountsResponse;
  getAllNotLinkedEmailAccountsResponse: GetAllNotLinkedEmailAccountsResponse;
  getNotLinkedEmailAccountsCountResponse: any;
  getSenderEmailsResponse: GetSenderEmailsResponse[];
  sequenceEmailsFilters: EmailFilter;
  taskAssigneeList: any;
  updatedSteps: any;
  sequenceActionStats: {
    success: number;
    failed: number;
  };
  lastAbsoluteDay: number;
  allConnectedEmailAccounts: any[];
  selectAllContactDetails: SelectAllContacts;
  sequenceStatsCounts: SequenceStatsCounts;
  isSequenceCreatedFromOnboarding: boolean;
  isStepsGuideVisible: boolean;
};

const initialState: State = {
  createSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequencesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceStatsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequencesStatsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  renameSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  pauseResumeSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  cloneSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAdminSettings: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteSequencesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addSequenceStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createSampleStepsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateSequenceStepExecutionDayRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateSequenceStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteSequenceStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  cloneSequenceStepVariantRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addSequenceStepVariantRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateSequenceStepVariantDataRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateSequenceStepVariantStatusRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteSequenceStepVariantRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateSequenceSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getContactFieldsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceStepsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addContactsFromCSVRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSchedulesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getConnectedEmailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getContactStatusCountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceContactListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getContactSequenceListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getContactSequenceStepListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  changeStatusOfContacts: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addContactsToStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addSampleProspectsToStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  removeContactFromSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectCountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addToStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getProspectRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceEmailSendRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  finishOnboardingRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceConfigRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequencePriorityConfigRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateSequencePriorityConfigRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getMyTemplateRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTeamTemplateRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailStatusOfStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailStepOfStatusRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getCustomDomainsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceProspectVerificationStatsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  sendSequenceProspectVerifyStartRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  uploadAttachmentRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteAttachmentRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSentEmailPreviewRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSingleProspectDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSingleProspectSequencesDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceScoreRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createEmailWritingFactorsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getLinkedEmailAccountsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  linkEmailToSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAllNotLinkedEmailAccountsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  removeConnectedEMailAccountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getNotLinkedEmailAccountsCountRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSenderEmailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  onOutcomeChangeRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTaskAssigneeListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  bulkPauseSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  bulkResumeSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  changeEmailStatusRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAllConnectedEmailAccountsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceTimezoneRequest: {},
  getSequenceTimezoneResponse: {},
  sequenceProspectBulkStatusUpdateRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  sequenceProspectBulkTagAssignRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  sequenceProspectBulkTagUnAssignRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  sequenceProspectBulkAddToSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  sequenceProspectBulkRemoveFromSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceStatsCountsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  uploadedAttachment: null,
  deletedAttachmentId: null,
  customDomains: [],
  myTemplates: [],
  myTemplatesMeta: null,
  teamTemplates: [],
  teamTemplatesMeta: null,
  pagination: {
    options: {
      page: 1,
      limit: constants.DEFAULT_SEQUENCE_PAGE_SIZE,
      sortBy: SequenceSortBy.CreatedAt,
      order: Order.Desc,
      progress: null,
      search: '',
      owners: '',
      clients: '',
    },
    count: 0,
    pagesCount: 0,
  },
  contactPagination: {
    pageNum: constants.DEFAULT_PAGE_NUM,
    pageSize: constants.DEFAULT_PAGE_SIZE,
  },
  totalSequences: 0,
  totalSelectedSequences: 0,
  sequenceStats: [],
  sequencesStats: [],
  sequences: [],
  sequence: null,
  schedules: [],
  connectedEmails: [],
  createdSequenceId: null,
  selectedSequence: [],
  contactFields: [],
  steps: [],
  importCSVReport: null,
  contacts: null,
  contactStatusFilterCount: null,
  contactStepFilterCount: {
    stepNumberWithCount: [],
    totalContacts: null,
  },
  selectedContacts: [],
  contactSequence: [],
  contactStep: [],
  addToSequenceResultCount: {
    failed: 0,
    successful: 0,
    resultData: null,
  },
  addToSequenceResultFromModalCount: {
    failed: 0,
    successful: 0,
    resultData: null,
  },
  pauseResumeResultCount: {
    failed: 0,
    success: 0,
  },
  prospectCount: 0,
  prospects: [],
  adminSettings: [],
  isSequenceMailSending: null,
  sequenceConfig: null,
  emailList: null,
  emailStatusOfStep: null,
  emailStepOfStatus: null,
  sequenceProspectsFilters: prospectsFiltersInitialState,
  sequenceProspectsVerificationStatsResponse: null,
  sequenceProspectsVerifyStartResponse: null,
  sentEmailPreview: null,

  selectedProspectId: null,
  selectedProspectData: null,
  selectedProspectSequences: [],
  createEmailWritingScoreForStepOne: false,
  sequenceScore: null,
  getLinkedEmailAccountsResponse: null,
  getAllNotLinkedEmailAccountsResponse: null,
  getNotLinkedEmailAccountsCountResponse: null,
  getSenderEmailsResponse: null,
  sequenceEmailsFilters: sequenceEmailsFiltersInitialState,
  taskAssigneeList: null,
  updatedSteps: null,
  sequenceActionStats: {
    success: 0,
    failed: 0,
  },
  lastAbsoluteDay: null,
  allConnectedEmailAccounts: [],
  selectAllContactDetails: null,
  sequenceStatsCounts: null,
  isSequenceCreatedFromOnboarding: false,
  isStepsGuideVisible: true,
};

const sequenceSlice = createSlice({
  name: 'sequence',
  initialState,
  reducers: {
    resetPauseResumeSequenceState: (state) => {
      state.pauseResumeSequenceRequest =
        initialState.pauseResumeSequenceRequest;
      state.renameSequenceRequest = initialState.renameSequenceRequest;
    },
    resetPaginationOptions: (state) => {
      state.pagination.options = initialState.pagination.options;
    },
    resetSequencesData: (state) => {
      state.sequences = initialState.sequences;
      state.totalSequences = initialState.totalSequences;
      state.totalSelectedSequences = initialState.totalSelectedSequences;
    },
    checkedChange: (state, action) => {
      if (action.payload.isSelect === true) {
        state.selectedSequence = state.selectedSequence.concat([
          action.payload.row,
        ]);
      } else {
        state.selectedSequence = state.selectedSequence.filter(
          ({ id }) => id !== action.payload.row.id,
        );
      }
    },
    checkChangeAll: (state, action) => {
      if (action.payload.isSelect === true) {
        state.selectedSequence = state.selectedSequence.concat(
          action.payload.rows,
        );
      } else {
        state.selectedSequence = state.selectedSequence.reduce((acc, item) => {
          let isPresent = false;
          action.payload.rows.forEach(({ id }) => {
            if (id === item.id) {
              isPresent = true;
            }
          });
          if (isPresent) {
            return acc;
          }
          return [...acc, item];
        }, []);
      }
    },
    resetSequence: (state) => {
      state.sequence = null;
    },
    resetSelectedSequences: (state) => {
      state.selectedSequence = initialState.selectedSequence;
    },
    changeContactPagination: (state, action) => {
      state.contactPagination = action.payload;
    },
    resetStep: (state) => {
      state.contactStep = initialState.contactStep;
      state.getContactSequenceStepListRequest =
        initialState.getContactSequenceStepListRequest;
    },
    resetOnboardingFinish: (state) => {
      state.finishOnboardingRequest = initialState.finishOnboardingRequest;
    },
    resetContacts: (state) => {
      state.contacts = initialState.contacts;
      state.contactStatusFilterCount = initialState.contactStatusFilterCount;
      state.contactPagination = initialState.contactPagination;
      state.getContactStatusCountRequest =
        initialState.getContactStatusCountRequest;
      state.getSequenceContactListRequest =
        initialState.getSequenceContactListRequest;
    },
    resetContactsRequest: (state) => {
      state.contacts = initialState.contacts;
      state.getSequenceContactListRequest =
        initialState.getSequenceContactListRequest;
    },
    resetEmail: (state) => {
      state.emailList = initialState.emailList;
      state.emailStatusOfStep = initialState.emailStatusOfStep;
      state.emailStepOfStatus = initialState.emailStepOfStatus;
      state.getEmailListRequest = initialState.getEmailListRequest;
      state.getEmailStatusOfStepRequest =
        initialState.getEmailStatusOfStepRequest;
      state.getEmailStepOfStatusRequest =
        initialState.getEmailStatusOfStepRequest;
    },
    selectAllSequenceContact: (state, action) => {
      if (action.payload.isSelect === true) {
        state.selectedContacts = state.selectedContacts.concat(
          action.payload.rows,
        );
      } else {
        state.selectedContacts = state.selectedContacts.reduce((acc, item) => {
          let isPresent = false;
          action.payload.rows.forEach(({ id }) => {
            if (id === item.id) {
              isPresent = true;
            }
          });
          if (isPresent) {
            return acc;
          }
          return [...acc, item];
        }, []);
      }
    },
    selectSingleSequenceContact: (state, action) => {
      if (action.payload.isSelect === true) {
        state.selectedContacts = state.selectedContacts.concat([
          action.payload.row,
        ]);
      } else {
        state.selectedContacts = state.selectedContacts.filter(
          ({ id }) => id !== action.payload.row.id,
        );
      }
    },
    selectAllContacts: (state, action) => {
      state.selectAllContactDetails = action.payload;
    },
    setSequenceEmailsFilter: (state, action) => {
      state.sequenceEmailsFilters = action.payload;
    },
    resetSequenceEmailsFilters: (state, action) => {
      const resetOff: SequenceEmailsFilterResetArg = action.payload;
      if (resetOff === 'all') {
        state.sequenceEmailsFilters = sequenceEmailsFiltersInitialState;
      } else {
        state.sequenceEmailsFilters[resetOff] = [];
      }
    },
    resetContactSequenceAndStepData: (state) => {
      state.contactSequence = initialState.contactSequence;
      state.contactStep = initialState.contactStep;
      state.getContactSequenceStepListRequest =
        initialState.getContactSequenceStepListRequest;
      state.getContactSequenceListRequest =
        initialState.getContactSequenceListRequest;
      state.addContactsToStepRequest = initialState.addContactsToStepRequest;
      state.removeContactFromSequenceRequest =
        initialState.removeContactFromSequenceRequest;
      state.changeStatusOfContacts = initialState.changeStatusOfContacts;
    },
    resetSelectedContacts: (state) => {
      state.selectedContacts = initialState.selectedContacts;
    },

    clearSelection: (state) => {
      state.selectAllContactDetails = initialState.selectAllContactDetails;
      state.selectedContacts = initialState.selectedContacts;
    },
    resetProspectsForModal: (state) => {
      state.getProspectRequest = initialState.getProspectRequest;
      state.getProspectCountRequest = initialState.getProspectCountRequest;
      state.addToStepRequest = initialState.addContactsToStepRequest;
      state.addToSequenceResultFromModalCount =
        initialState.addToSequenceResultFromModalCount;
      state.prospects = initialState.prospects;
      state.prospectCount = initialState.prospectCount;
    },
    resetSequenceSettings: (state) => {
      state.sequenceConfig = initialState.sequenceConfig;
      state.getSequenceConfigRequest = initialState.getSequenceConfigRequest;
      state.schedules = initialState.schedules;
      state.getSchedulesRequest = initialState.getSchedulesRequest;
      state.connectedEmails = initialState.connectedEmails;
      state.getConnectedEmailsRequest = initialState.getConnectedEmailsRequest;
    },
    resetSequenceConfigRequestStatus: (state) => {
      state.getSequenceConfigRequest = initialState.getSequenceConfigRequest;
    },
    resetSequenceSettingUpdateRequestStatus: (state) => {
      state.updateSequenceSettingsRequest =
        initialState.updateSequenceSettingsRequest;
    },
    resetSequenceSettingsContentRequest: (state) => {
      state.removeConnectedEMailAccountRequest =
        initialState.removeConnectedEMailAccountRequest;
    },
    resetSequenceProspectVerifyStart: (state) => {
      state.sequenceProspectsVerifyStartResponse =
        initialState.sequenceProspectsVerifyStartResponse;
      state.sendSequenceProspectVerifyStartRequest =
        initialState.sendSequenceProspectVerifyStartRequest;
    },
    setSequenceProspectsFilters: (state, action) => {
      state.sequenceProspectsFilters = { ...action.payload };
    },
    resetSequenceProspectsFilters: (state, action) => {
      const resetOff: ProspectFilterResetArg = action.payload;
      if (resetOff === 'all') {
        state.sequenceProspectsFilters = prospectsFiltersInitialState;
      } else {
        state.sequenceProspectsFilters[resetOff] = [];
      }
    },
    resetSequenceProspectVerificationStats: (state) => {
      state.sequenceProspectsVerificationStatsResponse =
        initialState.sequenceProspectsVerificationStatsResponse;
      state.sequenceProspectsVerifyStartResponse =
        initialState.sequenceProspectsVerifyStartResponse;
    },
    resetPauseResumeSequenceRequestState: (state) => {
      state.pauseResumeSequenceRequest =
        initialState.pauseResumeSequenceRequest;
    },
    resetUploadAttachmentRequest: (state) => {
      state.uploadAttachmentRequest = initialState.uploadAttachmentRequest;
      state.uploadedAttachment = initialState.uploadedAttachment;
    },
    resetDeleteAttachmentRequest: (state) => {
      state.deleteAttachmentRequest = initialState.deleteAttachmentRequest;
      state.deletedAttachmentId = initialState.deletedAttachmentId;
    },
    resetPauseResumeResultCount: (state) => {
      state.pauseResumeResultCount = initialState.pauseResumeResultCount;
    },
    resetGetSentEmailPreviewRequest: (state) => {
      state.getSentEmailPreviewRequest =
        initialState.getSentEmailPreviewRequest;
      state.sentEmailPreview = initialState.sentEmailPreview;
    },
    setSelectedProspectId: (state, action) => {
      state.selectedProspectId = action.payload;
    },
    resetSelectedProspectId: (state) => {
      state.selectedProspectId = initialState.selectedProspectId;
    },
    resetSelectedProspectData: (state) => {
      state.selectedProspectId = initialState.selectedProspectId;
      state.selectedProspectData = initialState.selectedProspectData;
      state.selectedProspectSequences = initialState.selectedProspectSequences;
      state.getSingleProspectDetailsRequest =
        initialState.getSingleProspectDetailsRequest;
      state.getSingleProspectSequencesDetailsRequest =
        initialState.getSingleProspectSequencesDetailsRequest;
    },
    sendCreateEmailWritingScoreForStepOne: (state, action) => {
      state.createEmailWritingScoreForStepOne = action.payload;
    },
    resetLinkEmailAccountsState: (state) => {
      state.linkEmailToSequenceRequest =
        initialState.linkEmailToSequenceRequest;
    },
    resetRemoveEmailAccountState: (state) => {
      state.removeConnectedEMailAccountRequest =
        initialState.removeConnectedEMailAccountRequest;
    },
    resetChangeOutcomeRequest: (state) => {
      state.onOutcomeChangeRequest = initialState.onOutcomeChangeRequest;
    },
    resetAddStepRequest: (state) => {
      state.addSequenceStepRequest = initialState.addSequenceStepRequest;
    },
    updateStepsRequest: (state, action) => {
      state.updatedSteps = action.payload;
    },
    resetBulkPauseSequenceRequest: (state) => {
      state.bulkPauseSequenceRequest = initialState.bulkPauseSequenceRequest;
    },
    resetBulkResumeSequenceRequest: (state) => {
      state.bulkResumeSequenceRequest = initialState.bulkResumeSequenceRequest;
    },
    setLastAbsoluteDay: (state, action) => {
      state.lastAbsoluteDay = action.payload;
    },
    resetChangeEmailStatusRequest: (state) => {
      state.changeEmailStatusRequest = initialState.changeEmailStatusRequest;
    },
    resetSequenceTimezoneData: (state, action: { payload: number }) => {
      delete state.getSequenceTimezoneRequest[action.payload];
      delete state.getSequenceTimezoneResponse[action.payload];
    },
    checkAll: (state, action) => {
      if (action.payload.status === true) {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          action.payload.rows.forEach(({ id: contactId }) => {
            state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails.deSelectedContactIds.filter(
              (id) => contactId !== id,
            );
          });
        }
        state.selectedContacts = state.selectedContacts.concat(
          action.payload.rows,
        );
      } else {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          action.payload.rows.forEach(({ id }) => {
            state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails?.deSelectedContactIds.concat(
              [id],
            );
          });
        }
        state.selectedContacts = state.selectedContacts.reduce((acc, item) => {
          let isPresent = false;
          action.payload.rows.forEach(({ id }) => {
            if (id === item.id) {
              isPresent = true;
            }
          });
          if (isPresent) {
            return acc;
          }
          return [...acc, item];
        }, []);
      }
    },
    checkSingle: (state, action) => {
      if (action.payload.status === true) {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails.deSelectedContactIds.filter(
            (id) => id !== action.payload.row.id,
          );
        }
        state.selectedContacts = state.selectedContacts.concat([
          action.payload.row,
        ]);
      } else {
        if (state.selectAllContactDetails?.isAllProspectsSelected) {
          state.selectAllContactDetails.deSelectedContactIds = state.selectAllContactDetails?.deSelectedContactIds.concat(
            [action.payload.row.id],
          );
        }
        state.selectedContacts = state.selectedContacts.filter(
          ({ id }) => id !== action.payload.row.id,
        );
      }
    },
    resetSequenceProspectBulkStatusUpdateRequest: (state) => {
      state.sequenceProspectBulkStatusUpdateRequest =
        initialState.sequenceProspectBulkStatusUpdateRequest;
    },
    resetSequenceProspectBulkTagAssignRequest: (state) => {
      state.sequenceProspectBulkTagAssignRequest =
        initialState.sequenceProspectBulkTagAssignRequest;
    },
    resetSequenceProspectBulkTagUnAssignRequest: (state) => {
      state.sequenceProspectBulkTagUnAssignRequest =
        initialState.sequenceProspectBulkTagUnAssignRequest;
    },
    resetSequenceProspectBulkAddToSequenceRequest: (state) => {
      state.sequenceProspectBulkAddToSequenceRequest =
        initialState.sequenceProspectBulkAddToSequenceRequest;
    },
    resetSequenceProspectBulkRemoveFromSequenceRequest: (state) => {
      state.sequenceProspectBulkRemoveFromSequenceRequest =
        initialState.sequenceProspectBulkRemoveFromSequenceRequest;
    },
    resetSequenceStatsCountsRequest: (state) => {
      state.getSequenceStatsCountsRequest =
        initialState.getSequenceStatsCountsRequest;
    },
    setIsSequenceCreatedFromOnboarding: (state, action) => {
      state.isSequenceCreatedFromOnboarding = action.payload;
    },
    toggleStepsGuideVisibility: (state, action) => {
      state.isStepsGuideVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Sequences
    builder.addCase(getSequencesRequest.pending, (state, action) => {
      state.getSequencesRequest.status = RequestStatus.Pending;
      state.getSequencesRequest.error = null;
      const keys = Object.keys(state.pagination.options);
      keys.forEach((key) => {
        state.pagination.options[key] = action.meta.arg[key];
      });
    });
    builder.addCase(getSequencesRequest.fulfilled, (state, action) => {
      state.getSequencesRequest.status = RequestStatus.Succeeded;
      state.getSequencesRequest.message = action.payload.message;
      state.sequences = action.payload.payload.sequences;
      state.totalSequences = action.payload.payload.totalSequences;
      state.totalSelectedSequences =
        action.payload.payload.totalSelectedSequences;
    });
    builder.addCase(getSequencesRequest.rejected, (state, action) => {
      state.getSequencesRequest.status = RequestStatus.Failed;
      state.getSequencesRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sequence Stats
    builder.addCase(getSequenceStatsRequest.pending, (state) => {
      state.getSequenceStatsRequest.status = RequestStatus.Pending;
      state.getSequenceStatsRequest.error = null;
    });
    builder.addCase(getSequenceStatsRequest.fulfilled, (state, action) => {
      state.getSequenceStatsRequest.status = RequestStatus.Succeeded;
      state.getSequenceStatsRequest.message = action.payload.message;
      state.sequenceStats = action.payload.payload;
    });
    builder.addCase(getSequenceStatsRequest.rejected, (state, action) => {
      state.getSequenceStatsRequest.status = RequestStatus.Failed;
      state.getSequenceStatsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sequences Stats
    builder.addCase(getSequencesStatsRequest.pending, (state) => {
      state.getSequencesStatsRequest.status = RequestStatus.Pending;
      state.getSequencesStatsRequest.error = null;
    });
    builder.addCase(getSequencesStatsRequest.fulfilled, (state, action) => {
      state.getSequencesStatsRequest.status = RequestStatus.Succeeded;
      state.getSequencesStatsRequest.message = action.payload.message;
      state.sequencesStats = action.payload.payload;
    });
    builder.addCase(getSequencesStatsRequest.rejected, (state, action) => {
      state.getSequencesStatsRequest.status = RequestStatus.Failed;
      state.getSequencesStatsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sequence
    builder.addCase(getSequenceRequest.pending, (state) => {
      state.getSequenceRequest.status = RequestStatus.Pending;
      state.getSequenceRequest.error = null;
    });
    builder.addCase(getSequenceRequest.fulfilled, (state, action) => {
      state.getSequenceRequest.status = RequestStatus.Succeeded;
      state.getSequenceRequest.message = action.payload.message;
      state.sequence = action.payload.payload;
      state.updatedSteps = null;
    });
    builder.addCase(getSequenceRequest.rejected, (state, action) => {
      state.getSequenceRequest.status = RequestStatus.Failed;
      state.getSequenceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Create Sequence
    builder.addCase(createSequenceRequest.pending, (state) => {
      state.createSequenceRequest.status = RequestStatus.Pending;
      state.createSequenceRequest.error = null;
    });
    builder.addCase(createSequenceRequest.fulfilled, (state, action) => {
      state.createSequenceRequest.status = RequestStatus.Succeeded;
      state.createSequenceRequest.message = action.payload.message;
      // Either get the entire sequence, with steps, variants and settings,
      // or don't assign the sequence here at all.
      // state.sequence = action.payload.payload;
      state.createdSequenceId = action.payload.payload.id;
    });
    builder.addCase(createSequenceRequest.rejected, (state, action) => {
      state.createSequenceRequest.status = RequestStatus.Failed;
      state.createSequenceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Rename Sequence
    builder.addCase(renameSequenceRequest.pending, (state) => {
      state.renameSequenceRequest.status = RequestStatus.Pending;
      state.renameSequenceRequest.error = null;
    });
    builder.addCase(renameSequenceRequest.fulfilled, (state, action) => {
      state.renameSequenceRequest.status = RequestStatus.Succeeded;
      state.renameSequenceRequest.message = action.payload.message;
    });
    builder.addCase(renameSequenceRequest.rejected, (state, action) => {
      state.renameSequenceRequest.status = RequestStatus.Failed;
      state.renameSequenceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Pause Resume Sequence
    builder.addCase(pauseResumeSequenceRequest.pending, (state) => {
      state.pauseResumeSequenceRequest.status = RequestStatus.Pending;
      state.pauseResumeSequenceRequest.error = null;
    });
    builder.addCase(pauseResumeSequenceRequest.fulfilled, (state, action) => {
      state.pauseResumeSequenceRequest.status = RequestStatus.Succeeded;
      state.pauseResumeSequenceRequest.message = action.payload.message;
    });
    builder.addCase(pauseResumeSequenceRequest.rejected, (state, action) => {
      state.pauseResumeSequenceRequest.status = RequestStatus.Failed;
      state.pauseResumeSequenceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Clone Sequence
    builder.addCase(cloneSequenceRequest.pending, (state) => {
      state.cloneSequenceRequest.status = RequestStatus.Pending;
      state.cloneSequenceRequest.error = null;
    });
    builder.addCase(cloneSequenceRequest.fulfilled, (state, action) => {
      state.cloneSequenceRequest.status = RequestStatus.Succeeded;
      state.cloneSequenceRequest.message = action.payload.message;
    });
    builder.addCase(cloneSequenceRequest.rejected, (state, action) => {
      state.cloneSequenceRequest.status = RequestStatus.Failed;
      state.cloneSequenceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Sequence
    builder.addCase(deleteSequenceRequest.pending, (state) => {
      state.deleteSequenceRequest.status = RequestStatus.Pending;
      state.deleteSequenceRequest.error = null;
    });
    builder.addCase(deleteSequenceRequest.fulfilled, (state, action) => {
      state.deleteSequenceRequest.status = RequestStatus.Succeeded;
      state.deleteSequenceRequest.message = action.payload.message;
    });
    builder.addCase(deleteSequenceRequest.rejected, (state, action) => {
      state.deleteSequenceRequest.status = RequestStatus.Failed;
      state.deleteSequenceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Sequences
    builder.addCase(deleteSequencesRequest.pending, (state) => {
      state.deleteSequencesRequest.status = RequestStatus.Pending;
      state.deleteSequencesRequest.error = null;
    });
    builder.addCase(deleteSequencesRequest.fulfilled, (state, action) => {
      state.deleteSequencesRequest.status = RequestStatus.Succeeded;
      state.deleteSequencesRequest.message = action.payload.message;
      state.selectedSequence = initialState.selectedSequence;
    });
    builder.addCase(deleteSequencesRequest.rejected, (state, action) => {
      state.deleteSequencesRequest.status = RequestStatus.Failed;
      state.deleteSequencesRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Add Sequence Step
    builder.addCase(addSequenceStepRequest.pending, (state) => {
      state.addSequenceStepRequest.status = RequestStatus.Pending;
      state.addSequenceStepRequest.error = null;
    });
    builder.addCase(addSequenceStepRequest.fulfilled, (state, action) => {
      state.addSequenceStepRequest.status = RequestStatus.Succeeded;
      state.addSequenceStepRequest.message = action.payload.message;
    });
    builder.addCase(addSequenceStepRequest.rejected, (state, action) => {
      state.addSequenceStepRequest.status = RequestStatus.Failed;
      state.addSequenceStepRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Create Sample Steps
    builder.addCase(createSampleStepsRequest.pending, (state) => {
      state.createSampleStepsRequest.status = RequestStatus.Pending;
      state.createSampleStepsRequest.error = null;
    });
    builder.addCase(createSampleStepsRequest.fulfilled, (state, action) => {
      state.createSampleStepsRequest.status = RequestStatus.Succeeded;
      state.createSampleStepsRequest.message = action.payload.message;
    });
    builder.addCase(createSampleStepsRequest.rejected, (state, action) => {
      state.createSampleStepsRequest.status = RequestStatus.Failed;
      state.createSampleStepsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Change Email Status
    builder.addCase(changeEmailStatusRequest.pending, (state) => {
      state.changeEmailStatusRequest.status = RequestStatus.Pending;
      state.changeEmailStatusRequest.error = null;
    });
    builder.addCase(changeEmailStatusRequest.fulfilled, (state, action) => {
      state.changeEmailStatusRequest.status = RequestStatus.Succeeded;
      state.changeEmailStatusRequest.message = action.payload.message;
    });
    builder.addCase(changeEmailStatusRequest.rejected, (state, action) => {
      state.changeEmailStatusRequest.status = RequestStatus.Failed;
      state.changeEmailStatusRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Sequence Step Execution Day/Time
    builder.addCase(updateSequenceStepExecutionDayRequest.pending, (state) => {
      state.updateSequenceStepExecutionDayRequest.status =
        RequestStatus.Pending;
      state.updateSequenceStepExecutionDayRequest.error = null;
    });
    builder.addCase(
      updateSequenceStepExecutionDayRequest.fulfilled,
      (state, action) => {
        state.updateSequenceStepExecutionDayRequest.status =
          RequestStatus.Succeeded;
        state.updateSequenceStepExecutionDayRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      updateSequenceStepExecutionDayRequest.rejected,
      (state, action) => {
        state.updateSequenceStepExecutionDayRequest.status =
          RequestStatus.Failed;
        state.updateSequenceStepExecutionDayRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Update Sequence Step Order
    builder.addCase(updateSequenceStepRequest.pending, (state) => {
      state.updateSequenceStepRequest.status = RequestStatus.Pending;
      state.updateSequenceStepRequest.error = null;
    });
    builder.addCase(updateSequenceStepRequest.fulfilled, (state, action) => {
      state.updateSequenceStepRequest.status = RequestStatus.Succeeded;
      state.updateSequenceStepRequest.message = action.payload.message;
    });
    builder.addCase(updateSequenceStepRequest.rejected, (state, action) => {
      state.updateSequenceStepRequest.status = RequestStatus.Failed;
      state.updateSequenceStepRequest.message = action.payload.message;
      state.updateSequenceStepRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Sequence Step
    builder.addCase(cloneSequenceStepVariantRequest.pending, (state) => {
      state.cloneSequenceStepVariantRequest.status = RequestStatus.Pending;
      state.cloneSequenceStepVariantRequest.error = null;
    });
    builder.addCase(
      cloneSequenceStepVariantRequest.fulfilled,
      (state, action) => {
        state.cloneSequenceStepVariantRequest.status = RequestStatus.Succeeded;
        state.cloneSequenceStepVariantRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      cloneSequenceStepVariantRequest.rejected,
      (state, action) => {
        state.cloneSequenceStepVariantRequest.status = RequestStatus.Failed;
        state.cloneSequenceStepVariantRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Delete Sequence Step
    builder.addCase(deleteSequenceStepRequest.pending, (state) => {
      state.deleteSequenceStepRequest.status = RequestStatus.Pending;
      state.deleteSequenceStepRequest.error = null;
    });
    builder.addCase(deleteSequenceStepRequest.fulfilled, (state, action) => {
      state.deleteSequenceStepRequest.status = RequestStatus.Succeeded;
      state.deleteSequenceStepRequest.message = action.payload.message;
    });
    builder.addCase(deleteSequenceStepRequest.rejected, (state, action) => {
      state.deleteSequenceStepRequest.status = RequestStatus.Failed;
      state.deleteSequenceStepRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Add Sequence Step Variant
    builder.addCase(addSequenceStepVariantRequest.pending, (state) => {
      state.addSequenceStepVariantRequest.status = RequestStatus.Pending;
      state.addSequenceStepVariantRequest.error = null;
    });
    builder.addCase(
      addSequenceStepVariantRequest.fulfilled,
      (state, action) => {
        state.addSequenceStepVariantRequest.status = RequestStatus.Succeeded;
        state.addSequenceStepVariantRequest.message = action.payload.message;
      },
    );
    builder.addCase(addSequenceStepVariantRequest.rejected, (state, action) => {
      state.addSequenceStepVariantRequest.status = RequestStatus.Failed;
      state.addSequenceStepVariantRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Sequence Step Variant Subject/Content
    builder.addCase(updateSequenceStepVariantDataRequest.pending, (state) => {
      state.updateSequenceStepVariantDataRequest.status = RequestStatus.Pending;
      state.updateSequenceStepVariantDataRequest.error = null;
    });
    builder.addCase(
      updateSequenceStepVariantDataRequest.fulfilled,
      (state, action) => {
        state.updateSequenceStepVariantDataRequest.status =
          RequestStatus.Succeeded;
        state.updateSequenceStepVariantDataRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      updateSequenceStepVariantDataRequest.rejected,
      (state, action) => {
        state.updateSequenceStepVariantDataRequest.status =
          RequestStatus.Failed;
        state.updateSequenceStepVariantDataRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Update Sequence Step Variant Status
    builder.addCase(updateSequenceStepVariantStatusRequest.pending, (state) => {
      state.updateSequenceStepVariantStatusRequest.status =
        RequestStatus.Pending;
      state.updateSequenceStepVariantStatusRequest.error = null;
    });
    builder.addCase(
      updateSequenceStepVariantStatusRequest.fulfilled,
      (state, action) => {
        state.updateSequenceStepVariantStatusRequest.status =
          RequestStatus.Succeeded;
        state.updateSequenceStepVariantStatusRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      updateSequenceStepVariantStatusRequest.rejected,
      (state, action) => {
        state.updateSequenceStepVariantStatusRequest.status =
          RequestStatus.Failed;
        state.updateSequenceStepVariantStatusRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Delete Sequence Step Variant
    builder.addCase(deleteSequenceStepVariantRequest.pending, (state) => {
      state.deleteSequenceStepVariantRequest.status = RequestStatus.Pending;
      state.deleteSequenceStepVariantRequest.error = null;
    });
    builder.addCase(
      deleteSequenceStepVariantRequest.fulfilled,
      (state, action) => {
        state.deleteSequenceStepVariantRequest.status = RequestStatus.Succeeded;
        state.deleteSequenceStepVariantRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      deleteSequenceStepVariantRequest.rejected,
      (state, action) => {
        state.deleteSequenceStepVariantRequest.status = RequestStatus.Failed;
        state.deleteSequenceStepVariantRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Update Sequence Settings
    builder.addCase(updateSequenceSettingsRequest.pending, (state) => {
      state.updateSequenceSettingsRequest.status = RequestStatus.Pending;
      state.updateSequenceSettingsRequest.error = null;
    });
    builder.addCase(
      updateSequenceSettingsRequest.fulfilled,
      (state, action) => {
        state.updateSequenceSettingsRequest.status = RequestStatus.Succeeded;
        state.updateSequenceSettingsRequest.message = action.payload.message;
      },
    );
    builder.addCase(updateSequenceSettingsRequest.rejected, (state, action) => {
      state.updateSequenceSettingsRequest.status = RequestStatus.Failed;
      state.updateSequenceSettingsRequest.message = action.payload.message;
      state.updateSequenceSettingsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Contact Fields
    builder.addCase(getContactFieldsRequest.pending, (state) => {
      state.getContactFieldsRequest.status = RequestStatus.Pending;
      state.getContactFieldsRequest.error = null;
    });
    builder.addCase(getContactFieldsRequest.fulfilled, (state, action) => {
      state.getContactFieldsRequest.status = RequestStatus.Succeeded;
      state.getContactFieldsRequest.message = action.payload.message;
      state.contactFields = action.payload.payload;
    });
    builder.addCase(getContactFieldsRequest.rejected, (state, action) => {
      state.getContactFieldsRequest.status = RequestStatus.Failed;
      state.getContactFieldsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sequence Steps
    builder.addCase(getSequenceStepsRequest.pending, (state) => {
      state.getSequenceStepsRequest.status = RequestStatus.Pending;
      state.getSequenceStepsRequest.error = null;
    });
    builder.addCase(getSequenceStepsRequest.fulfilled, (state, action) => {
      state.getSequenceStepsRequest.status = RequestStatus.Succeeded;
      state.getSequenceStepsRequest.message = action.payload.message;
      state.steps = action.payload.payload;
    });
    builder.addCase(getSequenceStepsRequest.rejected, (state, action) => {
      state.getSequenceStepsRequest.status = RequestStatus.Failed;
      state.getSequenceStepsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sequence Steps
    builder.addCase(addContactsFromCSVRequest.pending, (state) => {
      state.addContactsFromCSVRequest.status = RequestStatus.Pending;
      state.addContactsFromCSVRequest.error = null;
    });
    builder.addCase(addContactsFromCSVRequest.fulfilled, (state, action) => {
      state.addContactsFromCSVRequest.status = RequestStatus.Succeeded;
      state.addContactsFromCSVRequest.message = action.payload.message;
      state.importCSVReport = action.payload.payload;
    });

    builder.addCase(addContactsFromCSVRequest.rejected, (state, action) => {
      state.addContactsFromCSVRequest.status = RequestStatus.Failed;
      state.addContactsFromCSVRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Schedules
    builder.addCase(getSchedulesRequest.pending, (state) => {
      state.getSchedulesRequest.status = RequestStatus.Pending;
      state.getSchedulesRequest.error = null;
    });
    builder.addCase(getSchedulesRequest.fulfilled, (state, action) => {
      state.getSchedulesRequest.status = RequestStatus.Succeeded;
      state.getSchedulesRequest.message = action.payload.message;
      state.schedules = action.payload.payload;
    });
    builder.addCase(getSchedulesRequest.rejected, (state, action) => {
      state.getSchedulesRequest.status = RequestStatus.Failed;
      state.getSchedulesRequest.error =
        !action.payload.isHandled && action.payload;
    });
    // Connected Emails
    builder.addCase(getConnectedEmailsRequest.pending, (state) => {
      state.getConnectedEmailsRequest.status = RequestStatus.Pending;
      state.getConnectedEmailsRequest.error = null;
    });
    builder.addCase(getConnectedEmailsRequest.fulfilled, (state, action) => {
      state.getConnectedEmailsRequest.status = RequestStatus.Succeeded;
      state.getConnectedEmailsRequest.message = action.payload.message;
      state.connectedEmails = action.payload.payload;
    });
    builder.addCase(getConnectedEmailsRequest.rejected, (state, action) => {
      state.getConnectedEmailsRequest.status = RequestStatus.Failed;
      state.getConnectedEmailsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Change Contacts Status
    builder.addCase(changeStatusOfContacts.pending, (state) => {
      state.changeStatusOfContacts.status = RequestStatus.Pending;
      state.changeStatusOfContacts.error = null;
    });
    builder.addCase(changeStatusOfContacts.fulfilled, (state, action) => {
      state.changeStatusOfContacts.status = RequestStatus.Succeeded;
      state.changeStatusOfContacts.message = action.payload.message;
      state.pauseResumeResultCount = action.payload.payload;
    });
    builder.addCase(changeStatusOfContacts.rejected, (state, action) => {
      state.changeStatusOfContacts.status = RequestStatus.Failed;
      state.changeStatusOfContacts.error =
        !action.payload.isHandled && action.payload;
    });

    // Get the status filter list
    builder.addCase(getContactStatusCountRequest.pending, (state) => {
      state.getContactStatusCountRequest.status = RequestStatus.Pending;
      state.getContactStatusCountRequest.error = null;
    });
    builder.addCase(getContactStatusCountRequest.fulfilled, (state, action) => {
      state.getContactStatusCountRequest.status = RequestStatus.Succeeded;
      state.getContactStatusCountRequest.message = action.payload.message;
      const [payload] = action.payload.payload;
      state.contactStatusFilterCount = payload;
      state.contactStepFilterCount.totalContacts = payload?.total;
    });
    builder.addCase(getContactStatusCountRequest.rejected, (state, action) => {
      state.getContactStatusCountRequest.status = RequestStatus.Failed;
      state.getContactStatusCountRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Contact Sequence List
    builder.addCase(getContactSequenceListRequest.pending, (state) => {
      state.getContactSequenceListRequest.status = RequestStatus.Pending;
      state.getContactSequenceListRequest.error = null;
    });
    builder.addCase(
      getContactSequenceListRequest.fulfilled,
      (state, action) => {
        state.getContactSequenceListRequest.status = RequestStatus.Succeeded;
        state.getContactSequenceListRequest.message = action.payload.message;
        state.contactSequence = action.payload.payload;
      },
    );
    builder.addCase(getContactSequenceListRequest.rejected, (state, action) => {
      state.getContactSequenceListRequest.status = RequestStatus.Failed;
      state.getContactSequenceListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Contact Sequence  Step List
    builder.addCase(getContactSequenceStepListRequest.pending, (state) => {
      state.getContactSequenceStepListRequest.status = RequestStatus.Pending;
      state.getContactSequenceStepListRequest.error = null;
    });
    builder.addCase(
      getContactSequenceStepListRequest.fulfilled,
      (state, action) => {
        state.getContactSequenceStepListRequest.status =
          RequestStatus.Succeeded;
        state.getContactSequenceStepListRequest.message =
          action.payload.message;
        state.contactStep = action.payload.payload;
      },
    );
    builder.addCase(
      getContactSequenceStepListRequest.rejected,
      (state, action) => {
        state.getContactSequenceStepListRequest.status = RequestStatus.Failed;
        state.getContactSequenceStepListRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Add Contact to step
    builder.addCase(addContactsToStepRequest.pending, (state) => {
      state.addContactsToStepRequest.status = RequestStatus.Pending;
      state.addContactsToStepRequest.error = null;
    });
    builder.addCase(addContactsToStepRequest.fulfilled, (state, action) => {
      state.addContactsToStepRequest.status = RequestStatus.Succeeded;
      state.addContactsToStepRequest.message = action.payload.message;
      state.addToSequenceResultCount.failed = action.payload.payload.failed;
      state.addToSequenceResultCount.successful =
        action.payload.payload.successful;
      state.addToSequenceResultCount.resultData = action.payload.payload;
    });
    builder.addCase(addContactsToStepRequest.rejected, (state, action) => {
      state.addContactsToStepRequest.status = RequestStatus.Failed;
      state.addContactsToStepRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Add sample prospects to step
    builder.addCase(addSampleProspectsToStepRequest.pending, (state) => {
      state.addSampleProspectsToStepRequest.status = RequestStatus.Pending;
      state.addSampleProspectsToStepRequest.error = null;
    });
    builder.addCase(
      addSampleProspectsToStepRequest.fulfilled,
      (state, action) => {
        state.addSampleProspectsToStepRequest.status = RequestStatus.Succeeded;
        state.addSampleProspectsToStepRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      addSampleProspectsToStepRequest.rejected,
      (state, action) => {
        state.addSampleProspectsToStepRequest.status = RequestStatus.Failed;
        state.addSampleProspectsToStepRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Remove Contact from Sequence
    builder.addCase(removeContactFromSequenceRequest.pending, (state) => {
      state.removeContactFromSequenceRequest.status = RequestStatus.Pending;
      state.removeContactFromSequenceRequest.error = null;
    });
    builder.addCase(
      removeContactFromSequenceRequest.fulfilled,
      (state, action) => {
        state.removeContactFromSequenceRequest.status = RequestStatus.Succeeded;
        state.removeContactFromSequenceRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      removeContactFromSequenceRequest.rejected,
      (state, action) => {
        state.removeContactFromSequenceRequest.status = RequestStatus.Failed;
        state.removeContactFromSequenceRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get the filtered contact List
    builder.addCase(getSequenceContactListRequest.pending, (state) => {
      state.getSequenceContactListRequest.status = RequestStatus.Pending;
      state.getSequenceContactListRequest.error = null;
    });
    builder.addCase(
      getSequenceContactListRequest.fulfilled,
      (state, action) => {
        state.getSequenceContactListRequest.status = RequestStatus.Succeeded;
        state.getSequenceContactListRequest.message = action.payload.message;
        state.contacts = action.payload.payload.contacts;
      },
    );
    builder.addCase(getSequenceContactListRequest.rejected, (state, action) => {
      state.getSequenceContactListRequest.status = RequestStatus.Failed;
      state.getSequenceContactListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Getting the list of all the prospects
    builder.addCase(getProspectRequestModal.pending, (state) => {
      state.getProspectRequest.status = RequestStatus.Pending;
      state.getProspectRequest.error = null;
    });
    builder.addCase(getProspectRequestModal.fulfilled, (state, action) => {
      state.getProspectRequest.status = RequestStatus.Succeeded;
      state.getProspectRequest.message = action.payload.message;
      state.prospects = action.payload.payload.contacts;
    });
    builder.addCase(getProspectRequestModal.rejected, (state, action) => {
      state.getProspectRequest.status = RequestStatus.Failed;
      state.getProspectRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Getting the count of all the prospects for the pagination component
    builder.addCase(getProspectCountRequestModal.pending, (state) => {
      state.getProspectCountRequest.status = RequestStatus.Pending;
      state.getProspectCountRequest.error = null;
    });
    builder.addCase(getProspectCountRequestModal.fulfilled, (state, action) => {
      state.getProspectCountRequest.status = RequestStatus.Succeeded;
      state.getProspectCountRequest.message = action.payload.message;
      state.prospectCount = action.payload.payload;
    });
    builder.addCase(getProspectCountRequestModal.rejected, (state, action) => {
      state.getProspectCountRequest.status = RequestStatus.Failed;
      state.getProspectCountRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Admin Settings
    builder.addCase(getAdminSettingsRequest.pending, (state) => {
      state.getAdminSettings.status = RequestStatus.Pending;
      state.getAdminSettings.error = null;
    });
    builder.addCase(getAdminSettingsRequest.fulfilled, (state, action) => {
      state.getAdminSettings.status = RequestStatus.Succeeded;
      state.getAdminSettings.message = action.payload.message;
      state.adminSettings =
        action.payload.payload?.[0]?.shAccountSettingData || [];
    });
    builder.addCase(getAdminSettingsRequest.rejected, (state, action) => {
      state.getAdminSettings.status = RequestStatus.Failed;
      state.getAdminSettings.error =
        !action.payload.isHandled && action.payload;
    });

    // Add Contact to step
    builder.addCase(addToStepModalRequest.pending, (state) => {
      state.addToStepRequest.status = RequestStatus.Pending;
      state.addToStepRequest.error = null;
    });
    builder.addCase(addToStepModalRequest.fulfilled, (state, action) => {
      state.addToStepRequest.status = RequestStatus.Succeeded;
      state.addToStepRequest.message = action.payload.message;
      state.addToSequenceResultFromModalCount.failed =
        action.payload.payload.failed;
      state.addToSequenceResultFromModalCount.successful =
        action.payload.payload.successful;
      state.addToSequenceResultFromModalCount.resultData =
        action.payload.payload;
    });
    builder.addCase(addToStepModalRequest.rejected, (state, action) => {
      state.addToStepRequest.status = RequestStatus.Failed;
      state.addToStepRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get sequence mail send status
    builder.addCase(sequenceSendEmailStatus.pending, (state) => {
      state.getSequenceEmailSendRequest.status = RequestStatus.Pending;
      state.getSequenceEmailSendRequest.error = null;
    });
    builder.addCase(sequenceSendEmailStatus.fulfilled, (state, action) => {
      state.getSequenceEmailSendRequest.status = RequestStatus.Succeeded;
      state.isSequenceMailSending =
        action.payload.payload.sequenceHasStartedProcessingEmails;
    });
    builder.addCase(sequenceSendEmailStatus.rejected, (state, action) => {
      state.getSequenceEmailSendRequest.status = RequestStatus.Failed;
      state.finishOnboardingRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Finish onboarding
    builder.addCase(finishOnboardingRequest.pending, (state) => {
      state.finishOnboardingRequest.status = RequestStatus.Pending;
      state.finishOnboardingRequest.error = null;
    });
    builder.addCase(finishOnboardingRequest.fulfilled, (state, action) => {
      state.finishOnboardingRequest.status = RequestStatus.Succeeded;
      state.finishOnboardingRequest.message = action.payload.message;
    });
    builder.addCase(finishOnboardingRequest.rejected, (state, action) => {
      state.finishOnboardingRequest.status = RequestStatus.Failed;
      state.finishOnboardingRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sequence Config
    builder.addCase(getSequenceConfigRequest.pending, (state) => {
      state.getSequenceConfigRequest.status = RequestStatus.Pending;
      state.getSequenceConfigRequest.error = null;
    });
    builder.addCase(getSequenceConfigRequest.fulfilled, (state, action) => {
      state.getSequenceConfigRequest.status = RequestStatus.Succeeded;
      state.getSequenceConfigRequest.message = action.payload.message;
      state.sequenceConfig = action.payload.payload;
    });
    builder.addCase(getSequenceConfigRequest.rejected, (state, action) => {
      state.getSequenceConfigRequest.status = RequestStatus.Failed;
      state.getSequenceConfigRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sequence Config
    builder.addCase(getSequencePriorityConfigRequest.pending, (state) => {
      state.getSequencePriorityConfigRequest.status = RequestStatus.Pending;
      state.getSequencePriorityConfigRequest.error = null;
    });
    builder.addCase(
      getSequencePriorityConfigRequest.fulfilled,
      (state, action) => {
        state.getSequencePriorityConfigRequest.status = RequestStatus.Succeeded;
        state.sequenceConfig = {
          ...(state.sequenceConfig || {}),
          prioritySetting: action.payload.payload,
        };
      },
    );
    builder.addCase(
      getSequencePriorityConfigRequest.rejected,
      (state, action) => {
        state.getSequencePriorityConfigRequest.status = RequestStatus.Failed;
        state.getSequencePriorityConfigRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Sequence Config
    builder.addCase(updateSequencePriorityConfigRequest.pending, (state) => {
      state.updateSequencePriorityConfigRequest.status = RequestStatus.Pending;
      state.updateSequencePriorityConfigRequest.error = null;
    });
    builder.addCase(
      updateSequencePriorityConfigRequest.fulfilled,
      (state, action) => {
        state.updateSequencePriorityConfigRequest.status =
          RequestStatus.Succeeded;
        state.updateSequencePriorityConfigRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      updateSequencePriorityConfigRequest.rejected,
      (state, action) => {
        state.updateSequencePriorityConfigRequest.status = RequestStatus.Failed;
        state.updateSequencePriorityConfigRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get My Templates
    builder.addCase(getMyTemplateRequest.pending, (state) => {
      state.getMyTemplateRequest.status = RequestStatus.Pending;
      state.getMyTemplateRequest.error = null;
    });
    builder.addCase(getMyTemplateRequest.fulfilled, (state, action) => {
      state.getMyTemplateRequest.status = RequestStatus.Succeeded;
      state.myTemplates = action.payload.payload.items;
      state.myTemplatesMeta = action.payload.payload.meta;
    });
    builder.addCase(getMyTemplateRequest.rejected, (state) => {
      state.getMyTemplateRequest.status = RequestStatus.Failed;
    });

    // Get Team Templates
    builder.addCase(getTeamTemplateRequest.pending, (state) => {
      state.getTeamTemplateRequest.status = RequestStatus.Pending;
      state.getTeamTemplateRequest.error = null;
    });
    builder.addCase(getTeamTemplateRequest.fulfilled, (state, action) => {
      state.getTeamTemplateRequest.status = RequestStatus.Succeeded;
      state.teamTemplates = action.payload.payload.items;
      state.teamTemplatesMeta = action.payload.payload.meta;
    });
    builder.addCase(getTeamTemplateRequest.rejected, (state) => {
      state.getTeamTemplateRequest.status = RequestStatus.Failed;
    });

    // Get Email Status of Step Request
    builder.addCase(getEmailStatusOfStepRequest.pending, (state) => {
      state.getEmailStatusOfStepRequest.status = RequestStatus.Pending;
      state.getEmailStatusOfStepRequest.error = null;
    });
    builder.addCase(getEmailStatusOfStepRequest.fulfilled, (state, action) => {
      state.getEmailStatusOfStepRequest.status = RequestStatus.Succeeded;
      state.emailStatusOfStep = action.payload.payload;
    });
    builder.addCase(getEmailStatusOfStepRequest.rejected, (state) => {
      state.getEmailStatusOfStepRequest.status = RequestStatus.Failed;
    });

    // Get Email Step of Status Request
    builder.addCase(getEmailStepOfStatusRequest.pending, (state) => {
      state.getEmailStepOfStatusRequest.status = RequestStatus.Pending;
      state.getEmailStepOfStatusRequest.error = null;
    });
    builder.addCase(getEmailStepOfStatusRequest.fulfilled, (state, action) => {
      state.getEmailStepOfStatusRequest.status = RequestStatus.Succeeded;
      state.emailStepOfStatus = action.payload.payload;
    });
    builder.addCase(getEmailStepOfStatusRequest.rejected, (state) => {
      state.getEmailStepOfStatusRequest.status = RequestStatus.Failed;
    });

    // Get Email Request
    builder.addCase(getEmailListRequest.pending, (state) => {
      state.getEmailListRequest.status = RequestStatus.Pending;
      state.getEmailListRequest.error = null;
    });
    builder.addCase(getEmailListRequest.fulfilled, (state, action) => {
      state.getEmailListRequest.status = RequestStatus.Succeeded;
      state.emailList = action.payload.payload;
    });
    builder.addCase(getEmailListRequest.rejected, (state) => {
      state.getEmailListRequest.status = RequestStatus.Failed;
    });

    // Get Custom Domain
    builder.addCase(getCustomDomainsRequest.pending, (state) => {
      state.getCustomDomainsRequest.status = RequestStatus.Pending;
      state.getCustomDomainsRequest.error = null;
    });
    builder.addCase(getCustomDomainsRequest.fulfilled, (state, action) => {
      state.getCustomDomainsRequest.status = RequestStatus.Succeeded;
      state.customDomains = action.payload.payload;
    });
    builder.addCase(getCustomDomainsRequest.rejected, (state) => {
      state.getCustomDomainsRequest.status = RequestStatus.Failed;
    });

    // Get Sequence Prospect Verification Stats
    builder.addCase(
      getSequenceProspectVerificationStatsRequest.pending,
      (state) => {
        state.getSequenceProspectVerificationStatsRequest.status =
          RequestStatus.Pending;
        state.getSequenceProspectVerificationStatsRequest.message = null;
        state.getSequenceProspectVerificationStatsRequest.error = null;
      },
    );
    builder.addCase(
      getSequenceProspectVerificationStatsRequest.fulfilled,
      (state, action) => {
        state.getSequenceProspectVerificationStatsRequest.status =
          RequestStatus.Succeeded;
        state.sequenceProspectsVerificationStatsResponse =
          action.payload.payload;
      },
    );
    builder.addCase(
      getSequenceProspectVerificationStatsRequest.rejected,
      (state, action) => {
        state.getSequenceProspectVerificationStatsRequest.status =
          RequestStatus.Failed;
        state.getSequenceProspectVerificationStatsRequest.message =
          action.payload.message;
        state.getSequenceProspectVerificationStatsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Send Sequence Prospect Verification Start Request
    builder.addCase(sendSequenceProspectVerifyStartRequest.pending, (state) => {
      state.sendSequenceProspectVerifyStartRequest.status =
        RequestStatus.Pending;
      state.sendSequenceProspectVerifyStartRequest.message = null;
      state.sendSequenceProspectVerifyStartRequest.error = null;
    });
    builder.addCase(
      sendSequenceProspectVerifyStartRequest.fulfilled,
      (state, action) => {
        state.sendSequenceProspectVerifyStartRequest.status =
          RequestStatus.Succeeded;
        state.sequenceProspectsVerifyStartResponse = action.payload.payload;
        state.sendSequenceProspectVerifyStartRequest.message =
          action.payload.message;
        state.sendSequenceProspectVerifyStartRequest.error = null;
      },
    );
    builder.addCase(
      sendSequenceProspectVerifyStartRequest.rejected,
      (state, action) => {
        state.sendSequenceProspectVerifyStartRequest.status =
          RequestStatus.Failed;
        state.sendSequenceProspectVerifyStartRequest.message =
          action.payload.message;
        state.sendSequenceProspectVerifyStartRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Upload Attachment Request
    builder.addCase(uploadAttachmentRequest.pending, (state) => {
      state.uploadAttachmentRequest.status = RequestStatus.Pending;
      state.uploadAttachmentRequest.message = null;
      state.uploadAttachmentRequest.error = null;
    });
    builder.addCase(uploadAttachmentRequest.fulfilled, (state, action) => {
      state.uploadAttachmentRequest.status = RequestStatus.Succeeded;
      state.uploadedAttachment = action.payload.payload;
      state.uploadAttachmentRequest.message = action.payload.message;
      state.uploadAttachmentRequest.error = null;
    });
    builder.addCase(uploadAttachmentRequest.rejected, (state, action) => {
      state.uploadAttachmentRequest.status = RequestStatus.Failed;
      state.uploadAttachmentRequest.message = action.payload.message;
      state.uploadAttachmentRequest.error =
        !action.payload.isHandled && action.payload;

      state.uploadedAttachment = {
        attachmentId: null,
        attachmentIdentifier: action.meta.arg.formData
          .get('attachmentIdentifier')
          .toString(),
      };
    });

    // Delete Attachment Request
    builder.addCase(deleteAttachmentRequest.pending, (state) => {
      state.deleteAttachmentRequest.status = RequestStatus.Pending;
      state.deleteAttachmentRequest.message = null;
      state.deleteAttachmentRequest.error = null;
    });
    builder.addCase(deleteAttachmentRequest.fulfilled, (state, action) => {
      state.deleteAttachmentRequest.status = RequestStatus.Succeeded;
      state.deleteAttachmentRequest.message = action.payload.message;
      state.deleteAttachmentRequest.error = null;
      state.deletedAttachmentId = action.meta.arg.attachmentId;
    });
    builder.addCase(deleteAttachmentRequest.rejected, (state, action) => {
      state.deleteAttachmentRequest.status = RequestStatus.Failed;
      state.deleteAttachmentRequest.message = action.payload.message;
      state.deleteAttachmentRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sent Email Preview
    builder.addCase(getSentEmailPreviewRequest.pending, (state) => {
      state.getSentEmailPreviewRequest.status = RequestStatus.Pending;
      state.getSentEmailPreviewRequest.message = null;
      state.getSentEmailPreviewRequest.error = null;
    });
    builder.addCase(getSentEmailPreviewRequest.fulfilled, (state, action) => {
      state.getSentEmailPreviewRequest.status = RequestStatus.Succeeded;
      const { toEmail, ...rest } = action.payload.payload;
      state.sentEmailPreview = {
        ...rest,
        to: toEmail ? [toEmail] : [],
      };
    });
    builder.addCase(getSentEmailPreviewRequest.rejected, (state, action) => {
      state.getSentEmailPreviewRequest.status = RequestStatus.Failed;
      state.getSentEmailPreviewRequest.message = action.payload.message;
      state.getSentEmailPreviewRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Single Contact Details
    builder.addCase(getSingleProspectDetailsRequest.pending, (state) => {
      state.getSingleProspectDetailsRequest.status = RequestStatus.Pending;
      state.getSingleProspectDetailsRequest.error = null;
    });
    builder.addCase(
      getSingleProspectDetailsRequest.fulfilled,
      (state, action) => {
        state.getSingleProspectDetailsRequest.status = RequestStatus.Succeeded;
        state.getSingleProspectDetailsRequest.message = action.payload.message;
        state.selectedProspectData = action.payload.payload;
      },
    );
    builder.addCase(
      getSingleProspectDetailsRequest.rejected,
      (state, action) => {
        state.getSingleProspectDetailsRequest.status = RequestStatus.Failed;
        state.getSingleProspectDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Single Contact Sequence Details
    builder.addCase(
      getSingleProspectSequencesDetailsRequest.pending,
      (state) => {
        state.getSingleProspectSequencesDetailsRequest.status =
          RequestStatus.Pending;
        state.getSingleProspectSequencesDetailsRequest.error = null;
      },
    );
    builder.addCase(
      getSingleProspectSequencesDetailsRequest.fulfilled,
      (state, action) => {
        state.getSingleProspectSequencesDetailsRequest.status =
          RequestStatus.Succeeded;
        state.getSingleProspectSequencesDetailsRequest.message =
          action.payload.message;
        state.selectedProspectSequences = action.payload.payload;
      },
    );
    builder.addCase(
      getSingleProspectSequencesDetailsRequest.rejected,
      (state, action) => {
        state.getSingleProspectSequencesDetailsRequest.status =
          RequestStatus.Failed;
        state.getSingleProspectSequencesDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Sequence Score
    builder.addCase(getSequenceScoreRequest.pending, (state) => {
      state.getSequenceScoreRequest.status = RequestStatus.Pending;
      state.getSequenceScoreRequest.error = null;
    });
    builder.addCase(getSequenceScoreRequest.fulfilled, (state, action) => {
      state.getSequenceScoreRequest.status = RequestStatus.Succeeded;
      state.getSequenceScoreRequest.message = action.payload.message;
      state.sequenceScore = action.payload.payload;
    });
    builder.addCase(getSequenceScoreRequest.rejected, (state, action) => {
      state.getSequenceScoreRequest.status = RequestStatus.Failed;
      state.getSequenceScoreRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sequence Score
    builder.addCase(createEmailWritingFactorsRequest.pending, (state) => {
      state.createEmailWritingFactorsRequest.status = RequestStatus.Pending;
      state.createEmailWritingFactorsRequest.error = null;
    });
    builder.addCase(
      createEmailWritingFactorsRequest.fulfilled,
      (state, action) => {
        state.createEmailWritingFactorsRequest.status = RequestStatus.Succeeded;
        state.createEmailWritingFactorsRequest.message = action.payload.message;
        state.sequenceScore = action.payload.payload;
      },
    );
    builder.addCase(
      createEmailWritingFactorsRequest.rejected,
      (state, action) => {
        state.createEmailWritingFactorsRequest.status = RequestStatus.Failed;
        state.createEmailWritingFactorsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Linked Email Accounts Request
    builder.addCase(getLinkedEmailAccountsRequest.pending, (state) => {
      state.getLinkedEmailAccountsRequest.status = RequestStatus.Pending;
      state.getLinkedEmailAccountsRequest.message = null;
      state.getLinkedEmailAccountsRequest.error = null;
    });
    builder.addCase(
      getLinkedEmailAccountsRequest.fulfilled,
      (state, action) => {
        state.getLinkedEmailAccountsRequest.status = RequestStatus.Succeeded;
        state.getLinkedEmailAccountsResponse = action.payload.payload;
      },
    );
    builder.addCase(getLinkedEmailAccountsRequest.rejected, (state, action) => {
      state.getLinkedEmailAccountsRequest.status = RequestStatus.Failed;
      state.getLinkedEmailAccountsRequest.message = action.payload.message;
      state.getLinkedEmailAccountsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Send Link Email Account Request
    builder.addCase(linkEmailToSequenceRequest.pending, (state) => {
      state.linkEmailToSequenceRequest.status = RequestStatus.Pending;
      state.linkEmailToSequenceRequest.message = null;
      state.linkEmailToSequenceRequest.error = null;
    });
    builder.addCase(linkEmailToSequenceRequest.fulfilled, (state, action) => {
      state.linkEmailToSequenceRequest.status = RequestStatus.Succeeded;
      state.linkEmailToSequenceRequest.message = action.payload.message;
    });
    builder.addCase(linkEmailToSequenceRequest.rejected, (state, action) => {
      state.linkEmailToSequenceRequest.status = RequestStatus.Failed;
      state.linkEmailToSequenceRequest.message = action.payload.message;
      state.linkEmailToSequenceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get All Not Linked Email Accounts
    builder.addCase(getAllNotLinkedEmailAccountsRequest.pending, (state) => {
      state.getAllNotLinkedEmailAccountsRequest.status = RequestStatus.Pending;
      state.getAllNotLinkedEmailAccountsRequest.message = null;
      state.getAllNotLinkedEmailAccountsRequest.error = null;
    });
    builder.addCase(
      getAllNotLinkedEmailAccountsRequest.fulfilled,
      (state, action) => {
        state.getAllNotLinkedEmailAccountsRequest.status =
          RequestStatus.Succeeded;
        state.getAllNotLinkedEmailAccountsResponse = action.payload.payload;
      },
    );
    builder.addCase(
      getAllNotLinkedEmailAccountsRequest.rejected,
      (state, action) => {
        state.getAllNotLinkedEmailAccountsRequest.status = RequestStatus.Failed;
        state.getAllNotLinkedEmailAccountsRequest.message =
          action.payload.message;
        state.getAllNotLinkedEmailAccountsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Remove Connected Email Account
    builder.addCase(removeConnectedEMailAccountRequest.pending, (state) => {
      state.removeConnectedEMailAccountRequest.status = RequestStatus.Pending;
      state.removeConnectedEMailAccountRequest.message = null;
      state.removeConnectedEMailAccountRequest.error = null;
    });
    builder.addCase(
      removeConnectedEMailAccountRequest.fulfilled,
      (state, action) => {
        state.removeConnectedEMailAccountRequest.status =
          RequestStatus.Succeeded;
        state.removeConnectedEMailAccountRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      removeConnectedEMailAccountRequest.rejected,
      (state, action) => {
        state.removeConnectedEMailAccountRequest.status = RequestStatus.Failed;
        state.removeConnectedEMailAccountRequest.message =
          action.payload.message;
        state.removeConnectedEMailAccountRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Not Linked Email Accounts Count
    builder.addCase(getNotLinkedEmailAccountsCountRequest.pending, (state) => {
      state.getNotLinkedEmailAccountsCountRequest.status =
        RequestStatus.Pending;
      state.getNotLinkedEmailAccountsCountRequest.message = null;
      state.getNotLinkedEmailAccountsCountRequest.error = null;
    });
    builder.addCase(
      getNotLinkedEmailAccountsCountRequest.fulfilled,
      (state, action) => {
        state.getNotLinkedEmailAccountsCountRequest.status =
          RequestStatus.Succeeded;
        state.getNotLinkedEmailAccountsCountResponse = action.payload.payload;
      },
    );
    builder.addCase(
      getNotLinkedEmailAccountsCountRequest.rejected,
      (state, action) => {
        state.getNotLinkedEmailAccountsCountRequest.status =
          RequestStatus.Failed;
        state.getNotLinkedEmailAccountsCountRequest.message =
          action.payload.message;
        state.getNotLinkedEmailAccountsCountRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Sender Emails
    builder.addCase(getSenderEmailsRequest.pending, (state) => {
      state.getSenderEmailsRequest.status = RequestStatus.Pending;
      state.getSenderEmailsRequest.message = null;
      state.getSenderEmailsRequest.error = null;
    });
    builder.addCase(getSenderEmailsRequest.fulfilled, (state, action) => {
      state.getSenderEmailsRequest.status = RequestStatus.Succeeded;
      state.getSenderEmailsResponse = action.payload.payload;
    });
    builder.addCase(getSenderEmailsRequest.rejected, (state, action) => {
      state.getSenderEmailsRequest.status = RequestStatus.Failed;
      state.getSenderEmailsRequest.message = action.payload.message;
      state.getSenderEmailsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // On Outcome Change
    builder.addCase(onOutcomeChangeRequest.pending, (state) => {
      state.onOutcomeChangeRequest.status = RequestStatus.Pending;
      state.onOutcomeChangeRequest.message = null;
      state.onOutcomeChangeRequest.error = null;
    });
    builder.addCase(onOutcomeChangeRequest.fulfilled, (state, action) => {
      state.onOutcomeChangeRequest.status = RequestStatus.Succeeded;
      state.getSenderEmailsResponse = action.payload.payload;
    });
    builder.addCase(onOutcomeChangeRequest.rejected, (state, action) => {
      state.onOutcomeChangeRequest.status = RequestStatus.Failed;
      state.onOutcomeChangeRequest.message = action.payload.message;
      state.onOutcomeChangeRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Task Assignee List Request
    builder.addCase(getTaskAssigneeListRequest.pending, (state) => {
      state.getTaskAssigneeListRequest.status = RequestStatus.Pending;
      state.getTaskAssigneeListRequest.message = null;
      state.getTaskAssigneeListRequest.error = null;
    });
    builder.addCase(getTaskAssigneeListRequest.fulfilled, (state, action) => {
      state.getTaskAssigneeListRequest.status = RequestStatus.Succeeded;
      state.taskAssigneeList = action.payload.payload;
    });
    builder.addCase(getTaskAssigneeListRequest.rejected, (state, action) => {
      state.getTaskAssigneeListRequest.status = RequestStatus.Failed;
      state.getTaskAssigneeListRequest.message = action.payload.message;
      state.getTaskAssigneeListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Bulk Sequence Pause
    builder.addCase(bulkPauseSequenceRequest.pending, (state) => {
      state.bulkPauseSequenceRequest.status = RequestStatus.Pending;
      state.bulkPauseSequenceRequest.message = null;
      state.bulkPauseSequenceRequest.error = null;
    });
    builder.addCase(bulkPauseSequenceRequest.fulfilled, (state, action) => {
      state.bulkPauseSequenceRequest.status = RequestStatus.Succeeded;
      state.sequenceActionStats = action.payload.payload;
    });
    builder.addCase(bulkPauseSequenceRequest.rejected, (state, action) => {
      state.bulkPauseSequenceRequest.status = RequestStatus.Failed;
      state.bulkPauseSequenceRequest.message = action.payload.message;
      state.bulkPauseSequenceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Bulk Sequence Resume
    builder.addCase(bulkResumeSequenceRequest.pending, (state) => {
      state.bulkResumeSequenceRequest.status = RequestStatus.Pending;
      state.bulkResumeSequenceRequest.message = null;
      state.bulkResumeSequenceRequest.error = null;
    });
    builder.addCase(bulkResumeSequenceRequest.fulfilled, (state, action) => {
      state.bulkResumeSequenceRequest.status = RequestStatus.Succeeded;
      state.sequenceActionStats = action.payload.payload;
    });
    builder.addCase(bulkResumeSequenceRequest.rejected, (state, action) => {
      state.bulkResumeSequenceRequest.status = RequestStatus.Failed;
      state.bulkResumeSequenceRequest.message = action.payload.message;
      state.bulkResumeSequenceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get All Connected Email Accounts
    builder.addCase(getAllConnectedEmailAccountsRequest.pending, (state) => {
      state.getAllConnectedEmailAccountsRequest.status = RequestStatus.Pending;
      state.getAllConnectedEmailAccountsRequest.message = null;
      state.getAllConnectedEmailAccountsRequest.error = null;
    });
    builder.addCase(
      getAllConnectedEmailAccountsRequest.fulfilled,
      (state, action) => {
        state.getAllConnectedEmailAccountsRequest.status =
          RequestStatus.Succeeded;
        state.allConnectedEmailAccounts = action.payload.payload;
      },
    );
    builder.addCase(
      getAllConnectedEmailAccountsRequest.rejected,
      (state, action) => {
        state.getAllConnectedEmailAccountsRequest.status = RequestStatus.Failed;
        state.getAllConnectedEmailAccountsRequest.message =
          action.payload.message;
        state.getAllConnectedEmailAccountsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Sequence Timezone Request
    builder.addCase(getSequenceTimezoneRequest.pending, (state, action) => {
      const { uniqueId } = action.meta.arg;
      if (!state.getSequenceTimezoneRequest[uniqueId]) {
        state.getSequenceTimezoneRequest[uniqueId] = {
          status: RequestStatus.Ideal,
          message: null,
          error: null,
        };
      }
      state.getSequenceTimezoneRequest[uniqueId].status = RequestStatus.Pending;
      state.getSequenceTimezoneRequest[uniqueId].error = null;
    });
    builder.addCase(getSequenceTimezoneRequest.fulfilled, (state, action) => {
      const { uniqueId } = action.meta.arg;
      if (state.getSequenceTimezoneRequest[uniqueId]) {
        state.getSequenceTimezoneRequest[uniqueId].status =
          RequestStatus.Succeeded;
        state.getSequenceTimezoneRequest[uniqueId].error = null;

        if (action.payload?.payload?.timezone) {
          state.getSequenceTimezoneResponse[uniqueId] =
            action.payload.payload.timezone;
        }
      }
    });
    builder.addCase(getSequenceTimezoneRequest.rejected, (state, action) => {
      const { uniqueId } = action.meta.arg;
      if (state.getSequenceTimezoneRequest[uniqueId]) {
        state.getSequenceTimezoneRequest[uniqueId].status =
          RequestStatus.Failed;
        state.getSequenceTimezoneRequest[uniqueId].message =
          action.payload.message;
        state.getSequenceTimezoneRequest[uniqueId].error =
          !action.payload.isHandled && action.payload;
      }
    });
    // Sequence Prospect Bulk Status Update Request
    builder.addCase(
      sequenceProspectBulkStatusUpdateRequest.pending,
      (state) => {
        state.sequenceProspectBulkStatusUpdateRequest.status =
          RequestStatus.Pending;
        state.sequenceProspectBulkStatusUpdateRequest.message = null;
        state.sequenceProspectBulkStatusUpdateRequest.error = null;
      },
    );
    builder.addCase(
      sequenceProspectBulkStatusUpdateRequest.fulfilled,
      (state, action) => {
        state.sequenceProspectBulkStatusUpdateRequest.status =
          RequestStatus.Succeeded;
        state.sequenceProspectBulkStatusUpdateRequest.message =
          action.payload.payload.message;
      },
    );
    builder.addCase(
      sequenceProspectBulkStatusUpdateRequest.rejected,
      (state, action) => {
        state.sequenceProspectBulkStatusUpdateRequest.status =
          RequestStatus.Failed;
        state.sequenceProspectBulkStatusUpdateRequest.message =
          action.payload.message;
        state.sequenceProspectBulkStatusUpdateRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Sequence Prospect Bulk Tag Assign Request
    builder.addCase(sequenceProspectBulkTagAssignRequest.pending, (state) => {
      state.sequenceProspectBulkTagAssignRequest.status = RequestStatus.Pending;
      state.sequenceProspectBulkTagAssignRequest.message = null;
      state.sequenceProspectBulkTagAssignRequest.error = null;
    });
    builder.addCase(
      sequenceProspectBulkTagAssignRequest.fulfilled,
      (state, action) => {
        state.sequenceProspectBulkTagAssignRequest.status =
          RequestStatus.Succeeded;
        state.sequenceProspectBulkTagAssignRequest.message =
          action.payload.payload.message;
      },
    );
    builder.addCase(
      sequenceProspectBulkTagAssignRequest.rejected,
      (state, action) => {
        state.sequenceProspectBulkTagAssignRequest.status =
          RequestStatus.Failed;
        state.sequenceProspectBulkTagAssignRequest.message =
          action.payload.message;
        state.sequenceProspectBulkTagAssignRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Sequence Prospect Bulk Tag UnAssign Request
    builder.addCase(sequenceProspectBulkTagUnAssignRequest.pending, (state) => {
      state.sequenceProspectBulkTagUnAssignRequest.status =
        RequestStatus.Pending;
      state.sequenceProspectBulkTagUnAssignRequest.message = null;
      state.sequenceProspectBulkTagUnAssignRequest.error = null;
    });
    builder.addCase(
      sequenceProspectBulkTagUnAssignRequest.fulfilled,
      (state, action) => {
        state.sequenceProspectBulkTagUnAssignRequest.status =
          RequestStatus.Succeeded;
        state.sequenceProspectBulkTagUnAssignRequest.message =
          action.payload.payload.message;
      },
    );
    builder.addCase(
      sequenceProspectBulkTagUnAssignRequest.rejected,
      (state, action) => {
        state.sequenceProspectBulkTagUnAssignRequest.status =
          RequestStatus.Failed;
        state.sequenceProspectBulkTagUnAssignRequest.message =
          action.payload.message;
        state.sequenceProspectBulkTagUnAssignRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Sequence Prospect Bulk Add To Sequence Request
    builder.addCase(
      sequenceProspectBulkAddToSequenceRequest.pending,
      (state) => {
        state.sequenceProspectBulkAddToSequenceRequest.status =
          RequestStatus.Pending;
        state.sequenceProspectBulkAddToSequenceRequest.message = null;
        state.sequenceProspectBulkAddToSequenceRequest.error = null;
      },
    );
    builder.addCase(
      sequenceProspectBulkAddToSequenceRequest.fulfilled,
      (state, action) => {
        state.sequenceProspectBulkAddToSequenceRequest.status =
          RequestStatus.Succeeded;
        state.sequenceProspectBulkAddToSequenceRequest.message =
          action.payload.payload.message;
      },
    );
    builder.addCase(
      sequenceProspectBulkAddToSequenceRequest.rejected,
      (state, action) => {
        state.sequenceProspectBulkAddToSequenceRequest.status =
          RequestStatus.Failed;
        state.sequenceProspectBulkAddToSequenceRequest.message =
          action.payload.message;
        state.sequenceProspectBulkAddToSequenceRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Sequence Prospect Bulk Remove From Sequence Request
    builder.addCase(
      sequenceProspectBulkRemoveFromSequenceRequest.pending,
      (state) => {
        state.sequenceProspectBulkRemoveFromSequenceRequest.status =
          RequestStatus.Pending;
        state.sequenceProspectBulkRemoveFromSequenceRequest.message = null;
        state.sequenceProspectBulkRemoveFromSequenceRequest.error = null;
      },
    );
    builder.addCase(
      sequenceProspectBulkRemoveFromSequenceRequest.fulfilled,
      (state, action) => {
        state.sequenceProspectBulkRemoveFromSequenceRequest.status =
          RequestStatus.Succeeded;
        state.sequenceProspectBulkRemoveFromSequenceRequest.message =
          action.payload.payload.message;
      },
    );
    builder.addCase(
      sequenceProspectBulkRemoveFromSequenceRequest.rejected,
      (state, action) => {
        state.sequenceProspectBulkRemoveFromSequenceRequest.status =
          RequestStatus.Failed;
        state.sequenceProspectBulkRemoveFromSequenceRequest.message =
          action.payload.message;
        state.sequenceProspectBulkRemoveFromSequenceRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Sequence Stats Counts Request
    builder.addCase(getSequenceStatsCountsRequest.pending, (state) => {
      state.getSequenceStatsCountsRequest.status = RequestStatus.Pending;
      state.getSequenceStatsCountsRequest.message = null;
      state.getSequenceStatsCountsRequest.error = null;
    });
    builder.addCase(
      getSequenceStatsCountsRequest.fulfilled,
      (state, action) => {
        state.getSequenceStatsCountsRequest.status = RequestStatus.Succeeded;
        state.getSequenceStatsCountsRequest.message =
          action.payload.payload.message;
        state.sequenceStatsCounts = action.payload.payload;
      },
    );
    builder.addCase(getSequenceStatsCountsRequest.rejected, (state, action) => {
      state.getSequenceStatsCountsRequest.status = RequestStatus.Failed;
      state.getSequenceStatsCountsRequest.message = action.payload.message;
      state.getSequenceStatsCountsRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  resetPaginationOptions,
  checkedChange,
  checkChangeAll,
  resetSequence,
  resetContacts,
  changeContactPagination,
  selectSingleSequenceContact,
  selectAllSequenceContact,
  setSequenceEmailsFilter,
  resetSequenceEmailsFilters,
  resetContactSequenceAndStepData,
  resetStep,
  resetSelectedContacts,
  resetSelectedSequences,
  resetProspectsForModal,
  resetOnboardingFinish,
  resetEmail,
  resetSequenceSettings,
  resetSequenceSettingUpdateRequestStatus,
  resetSequenceConfigRequestStatus,
  resetSequenceSettingsContentRequest,
  setSequenceProspectsFilters,
  resetSequenceProspectsFilters,
  resetPauseResumeSequenceState,
  resetSequenceProspectVerificationStats,
  resetSequenceProspectVerifyStart,
  resetPauseResumeSequenceRequestState,
  resetUploadAttachmentRequest,
  resetDeleteAttachmentRequest,
  resetPauseResumeResultCount,
  resetGetSentEmailPreviewRequest,
  setSelectedProspectId,
  resetSelectedProspectId,
  resetSelectedProspectData,
  sendCreateEmailWritingScoreForStepOne,
  resetLinkEmailAccountsState,
  resetRemoveEmailAccountState,
  resetChangeOutcomeRequest,
  resetAddStepRequest,
  updateStepsRequest,
  resetBulkPauseSequenceRequest,
  resetBulkResumeSequenceRequest,
  resetSequencesData,
  setLastAbsoluteDay,
  resetChangeEmailStatusRequest,
  resetContactsRequest,
  resetSequenceTimezoneData,
  clearSelection,
  selectAllContacts,
  checkAll,
  checkSingle,
  resetSequenceProspectBulkStatusUpdateRequest,
  resetSequenceProspectBulkTagAssignRequest,
  resetSequenceProspectBulkTagUnAssignRequest,
  resetSequenceProspectBulkAddToSequenceRequest,
  resetSequenceProspectBulkRemoveFromSequenceRequest,
  resetSequenceStatsCountsRequest,
  setIsSequenceCreatedFromOnboarding,
  toggleStepsGuideVisibility,
  setIsEspAction,
  resetIsEspAction,
} = sequenceSlice.actions;
export default sequenceSlice.reducer;
