export enum SequencePauseReasons {
  BounceLimitReached = 1,
  EmailAccountDisconnected = 2,
  PlanExpired = 3,
  ManuallyPaused = 4,
  EmailSendingLimitReached = 5,
  TrialQuotaExhausted = 6,
  PlanPaused = 7,
  AgencyClientDeleted = 8,
  EmailAccountDeleted = 9,
  PlanDowngraded = 10,
  OverLimitConnectedEmails = 11,
  AgencyClientDisabled = 12,
  EmailAccountPaused = 13,
}

export const sequencePauseMessages = {
  [SequencePauseReasons.BounceLimitReached]: 'bounce_limit_reached',
  [SequencePauseReasons.EmailAccountDisconnected]: 'email_account_disconnected',
  [SequencePauseReasons.PlanExpired]: 'plan_expired',
  [SequencePauseReasons.ManuallyPaused]: 'manually_paused',
  [SequencePauseReasons.EmailSendingLimitReached]:
    'email_sending_limit_reached',
  [SequencePauseReasons.TrialQuotaExhausted]: 'trial_quota_exhausted',
  [SequencePauseReasons.PlanPaused]: 'plan_paused',
  [SequencePauseReasons.AgencyClientDeleted]: 'agency_client_deleted',
  [SequencePauseReasons.AgencyClientDisabled]: 'agency_client_disabled',
  [SequencePauseReasons.EmailAccountDeleted]: 'email_account_deleted',
  [SequencePauseReasons.PlanDowngraded]: 'plan_downgraded',
  [SequencePauseReasons.OverLimitConnectedEmails]:
    'over_limit_connected_emails',
  [SequencePauseReasons.EmailAccountPaused]: 'email_account_paused',
};
